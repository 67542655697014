<template>
    <modal
        id="profile"
        ref="profile"
        :title="$t('page.myAccount.heading')"
        type="user"
    >
        <form
            id="profile"
            @submit.prevent="save"
        >
            <div
                class="form-group w100"
                :class="{ error: errors.name }"
            >
                <label>{{ $t('form.fields.label.name') }}</label>
                <input
                    v-model="input.name"
                    :placeholder="$t('form.fields.placeholder.alias')"
                    type="text"
                    class="form-control"
                    name="name"
                >
                <span
                    v-if="errors.name"
                    class="error-message"
                >{{ errors.name }}</span>
            </div>
            <div
                class="form-group w100"
                :class="{ error: errors.last_name }"
            >
                <label>{{ $t('form.fields.label.last_name') }}</label>
                <input
                    v-model="input.last_name"
                    :placeholder="$t('form.fields.placeholder.lastName')"
                    type="text"
                    class="form-control"
                    name="last_name"
                >
                <span
                    v-if="errors.last_name"
                    class="error-message"
                >{{
                    errors.last_name
                }}</span>
            </div>
            <div
                class="form-group w100"
                :class="{ error: errors.first_name }"
            >
                <label>{{ $t('form.fields.label.first_name') }}</label>
                <input
                    v-model="input.first_name"
                    :placeholder="$t('form.fields.placeholder.firstName')"
                    type="text"
                    class="form-control"
                    name="first_name"
                >
                <span
                    v-if="errors.first_name"
                    class="error-message"
                >{{
                    errors.first_name
                }}</span>
            </div>
            <div
                class="form-group w100"
                :class="{ error: errors.phone }"
            >
                <label>{{ $t('form.fields.label.phone') }}</label>
                <input
                    v-model="input.phone"
                    :placeholder="$t('form.fields.placeholder.phone')"
                    type="text"
                    class="form-control"
                    name="phone"
                >
                <span
                    v-if="errors.phone"
                    class="error-message"
                >{{
                    errors.phone
                }}</span>
            </div>
            <div class="actions">
                <b-button
                    variant="primary"
                    class="link lg cancel"
                    @click="closeModal()"
                >
                    {{ $t('modal.general.cancelButton') }}
                </b-button>
                <b-button
                    :disabled="sending"
                    variant="primary"
                    class="fill lg save"
                    type="submit"
                >
                    {{ $t('modal.general.saveButton') }}
                </b-button>
            </div>
        </form>
    </modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal';
    export default {
        name: 'EditProfileDataModal',
        components: {
            Modal,
        },
        data() {
            return {
                input: {
                    name: '',
                    first_name: '',
                    last_name: '',
                    phone: '',
                },
                errors: {},
                sending: false,
            };
        },
        computed: {
            ...mapState('user', ['generalData', 'inputErrors']),
        },
        methods: {
            ...mapActions('user', ['updateProfile']),
            closeModal() {
                this.$refs.profile.closeModal();
            },
            async save() {
                let modifiedInput = {
                    ...this.input,
                    alias: this.input.name,
                };
                delete modifiedInput.name;
                this.errors = formValidations(modifiedInput);

                if(this.errors.alias){
                    this.errors.name = this.errors.alias;
                    delete this.errors.alias;
                }

                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                await this.updateProfile(this.input);
                this.sending = false;
                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }
                this.$toast.open({
                    message: this.$t('notification.profileDataUpdated'),
                    type: 'success',
                    duration: 10000,
                });
                this.closeModal();
            },
            initialize() {
                this.input.name = this.generalData.name;
                this.input.first_name = this.generalData.first_name;
                this.input.last_name = this.generalData.last_name;
                this.input.phone = this.generalData.phone;
            },
        },
        mounted() {
            this.initialize();
        },
    };
</script>
